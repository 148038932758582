import GATSBY_COMPILED_MDX from "/home/runner/work/aimlessanalysis/aimlessanalysis/content/life/the_three_types_of_company_culture.mdx";
import React from "react";
import {graphql} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import {Link} from "gatsby";
import Layout from "./layout";
import Seo from "./seo";
const shortcodes = {
  Link
};
function PageTemplate({data: {mdx}, children}) {
  return React.createElement(Layout, null, React.createElement(Seo, {
    title: mdx.frontmatter.title
  }), React.createElement("div", {
    className: "post"
  }, React.createElement("h1", null, mdx.frontmatter.title), React.createElement("p", null, mdx.frontmatter.author, " - ", mdx.frontmatter.date), React.createElement(MDXProvider, {
    components: shortcodes
  }, children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
  query PostTemplate($id: String!) {
    mdx(id: { eq: $id }) {
      id
      frontmatter {
        title
        author
        date(formatString: "MMMM Do, YYYY")
      }
    }
  }
`;
